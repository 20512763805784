import { Stimulus, Controller } from "#/layouts/js/stimulus"
import { colorScores } from "./utils"

let loadingNextPage = false

const loadNextPage = function() {
  if (loadingNextPage) return

  const table = document.getElementById("search-results")
  if (!table) return

  const page = parseInt(table.getAttribute("data-page")) || 1
  if (page >= 10) return

  loadingNextPage = true
  table.querySelector("tbody").insertAdjacentHTML("beforeend", `
    <tr>
      <td colspan=3 class="text-center text-muted">
        <i class="fa-solid fa-spinner fa-spin-pulse fa-4x"></i>
      </td>
    </tr>
  `)

  fetch(location.href + `&page=${page+1}`)
    .then(response => response.text())
    .then(text => {
      table.querySelector("tbody tr:last-child").remove()
      table.querySelector("tbody").insertAdjacentHTML("beforeend", text)
      table.setAttribute("data-page", page + 1)
      colorScores()
      loadingNextPage = false
    })
}

Stimulus.register("paginate", class extends Controller {
  next() {
    if (this.element.scrollTop + this.element.getBoundingClientRect().height > this.element.scrollHeight - 300)
      loadNextPage()
  }
})

addEventListener("scroll", function() {
  if (document.querySelector(".overflow-y-scroll")) return

  if (window.scrollY + window.innerHeight > document.body.scrollHeight - 300)
    loadNextPage()
})
