import "../css"
import "bootstrap/js/dist/modal"

import * as Turbo from "@hotwired/turbo"

import Treeselect from "treeselectjs"
import "treeselectjs/dist/treeselectjs.css"

import { colorScores, tabletMode } from "./utils"
import { traverseMarks } from "./hits"
import { selectResult } from "./results"
import "./resize"
import "./snippet"
import "./pagination"
import "./disclaimer"

addEventListener("turbo:load", () => {
  const categoriesEl = document.getElementById("search-categories")
  const inputEl = document.querySelector('input[name="categories"]')
  if (!categoriesEl) return

  fetch("/documents/categories")
    .then(response => response.json())
    .then(json => {
      categoriesEl.replaceChildren()
      const tree = new Treeselect({
        parentHtmlContainer: categoriesEl,
        value: inputEl.value.split(",").map(i => parseInt(i)),
        options: json,
        isGroupedValue: true,
        showTags: false,
        placeholder: "Restrict to categories..."
      })

      tree.srcElement.addEventListener("input", e => inputEl.value = e.detail)
    })
})

addEventListener("turbo:frame-render", e => {
  if (e.target.id == "search-result-document")
    e.target.parentElement.scrollTop = 0

  colorScores()
})

addEventListener("copy", function(e){
  e.clipboardData.setData("text/plain", window.getSelection().toString())
  e.preventDefault()
})

addEventListener("keydown", function(e) {
  const focused = document.activeElement
  // Disable navigation when form fields are focused
  if (focused.tagName == "INPUT" && focused.getAttribute("type") != "checkbox") return

  // Move between search results
  if (["ArrowUp", "ArrowDown"].includes(e.key)) {
    let result = document.querySelector("tr[data-document].table-active")
    if (result) {
      e.preventDefault()

      const dir = e.key == "ArrowUp" ? "previousElementSibling" : "nextElementSibling"
      const amount = (e.ctrlKey || e.metaKey) ? 10 : 1

      for (let i = 1; i <= amount; i++) {
        result = result[dir] || result
      }

      if (result) selectResult(result)
      return
    }
  }

  // Move between hits in document
  if (["ArrowLeft", "ArrowRight"].includes(e.key) && document.querySelector("mark")) {
    e.preventDefault()

    let amount = e.key == "ArrowLeft" ? -1 : 1
    if (e.ctrlKey || e.metaKey) amount *= 10
    traverseMarks(amount)
    return
  }

  // Go to document in tablet mode
  const result = document.querySelector("tr[data-document].table-active")
  if (e.key == "Enter" && result && tabletMode()) {
    Turbo.visit(result.getAttribute("data-document"))
    return
  }

  // Return from document in tablet mode
  if (e.key == "Backspace" && location.pathname.match(/\/documents\/\d+/)) {
    history.back()
    return
  }
})
