const tabletMode = () => {
  const doc = document.getElementById("search-result-document")
  return doc && !doc.offsetParent
}

const colorScores = function() {
  const topScore = parseFloat(document.querySelector('.badge[class*="score"]').innerText)
  if (!topScore) return

  document.querySelectorAll(".badge.score").forEach(el => {
    const score = parseFloat(el.innerText)
    const bin = Math.round(score / topScore * 5) || 0

    el.classList.remove("score")
    el.classList.add(`score-${bin}`)
  })
}

export { tabletMode, colorScores }
