import { Stimulus, Controller } from "#/layouts/js/stimulus"

const getMarks = function() {
  const marks = document.querySelectorAll("#search-result-document mark")
  const active = document.querySelector("#search-result-document mark.active")
  let index = Array.prototype.indexOf.call(marks, active)

  return [index, marks]
}

const showHits = function() {
  const [activeIndex, marks] = getMarks()
  const current = activeIndex == -1 ? "–" : (activeIndex + 1).toString()
  document.getElementById("hits").innerText = `${current}/${marks.length}`
}

const traverseMarks = function(amount) {
  const [activeIndex, marks] = getMarks()
  let newIndex = activeIndex + amount

  if (newIndex >= marks.length) {
    newIndex = 0
  } else if (newIndex < 0) {
    newIndex = marks.length - 1
  }

  if (marks[activeIndex]) marks[activeIndex].classList.remove("active")
  marks[newIndex].classList.add("active")
  marks[newIndex].scrollIntoView({ block: "center", behavior: "smooth" })
  showHits()
}

Stimulus.register("mark", class extends Controller {
  connect() {
    showHits()
  }

  move(e) {
    traverseMarks(e.params.dir)
  }
})

export { traverseMarks }
